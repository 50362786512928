<template>
	<TextField
		v-bind="$props"
		:type="showPassword ? 'text' : 'password'"
		:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
		:rules="{ required: true, ...$props.rules }"
		placeholder="Please enter your password"
		outlined
		@click:append="showPassword = !showPassword"
		v-on="$listeners"
	/>
</template>

<script>
import TextField from './text-field.vue'

export default {
	name: 'password-field',
	components: { TextField },
	extends: TextField,
	data () {
		return {
			showPassword: false
		}
	}
}
</script>
