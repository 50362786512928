<template>
	<LoginLayout
		v-if="showLoginScreen"
		:form-title="formTitle"
		portal-name="Administration Portal"
	>
		<template #form>
			<validation-observer
				v-if="!showOtpLinkSentScreen"
				#default="{ handleSubmit }"
				class="cassie-vertical-sm"
			>
				<TextField
					v-model="username"
					rules="required"
					outlined
					label="Username *"
					placeholder="Please enter your email address"
				/>
				<PasswordField
					v-model="password"
					label="Password *"
				/>
				<v-card-actions class="pa-0">
					<TextButton @click="navigateToForgottenPassword">
						Forgotten Password?
					</TextButton>
					<v-spacer />
					<PrimaryActionButton
						:disabled="tryingToLogIn"
						:loading="tryingToLogIn"
						@click="handleSubmit(tryToLogIn)"
					>
						Login
					</PrimaryActionButton>
				</v-card-actions>
			</validation-observer>
			<div v-else>
				<p>
					You have been emailed a link to grant you access to Cassie. Click the link in the email which will open a new tab in your browser.
				</p>
				<p>
					You can close this tab
				</p>
			</div>
		</template>
	</LoginLayout>
</template>

<script>
import { mapActions } from 'vuex'
import appConfig from '../../../app.config.json'
import TextButton from '../../../../../shared/components/text-button.vue'
import PasswordField from '../../../../../shared/components/password-field.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import LoginLayout from '../../../../../shared/layouts/login-layout.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import { FORGOTTEN_PASSWORD } from '../../../router/route-names.js'

export default {
	components: { TextField, LoginLayout, PrimaryActionButton, TextButton, PasswordField },
	page: {
		title: 'Log in',
		meta: [{ name: 'description', content: `Log in to ${appConfig.title}` }]
	},
	props: {
		previousRoute: Object
	},
	data () {
		return {
			username: '',
			password: '',
			showPassword: false,
			authError: null,
			tryingToLogIn: false,
			saving: false,
			showLoginScreen: false,
			showOtpLinkSentScreen: false,
			formTitle: 'Sign In'
		}
	},
	computed: {
		placeholders () {
			return process.env.NODE_ENV === 'production'
				? {}
				: {
					username: 'Please enter your email address',
					password: 'Please enter your password'
				}
		}
	},
	async created () {
		// Show this login screen if running locally or if there is no 'legacy url' to migrate to. Otherwise redirect to 'legacy' portal login
		const migrationUrl = await this.getLegacyUrl()
		if (window.location.hostname === 'localhost' || (migrationUrl == null || migrationUrl[0] === true)) {
			this.showLoginScreen = true
		} else {
			location.href = `https://${migrationUrl[1]}/`
		}
	},
	methods: {
		...mapActions('auth', ['logIn', 'logOut', 'getLegacyUrl']),
		// Try to log the user in with the username
		// and password they provided.
		tryToLogIn () {
			this.saving = true
			this.tryingToLogIn = true
			// Reset the authError if it existed.
			this.authError = null
			return this.logIn({
				username: this.username,
				password: this.password
			})
				.then(user => {
					this.tryingToLogIn = false
					this.showOtpLinkSentScreen = user.otpLoginRequired
					if (this.showOtpLinkSentScreen) {
						this.formTitle = 'Link has been sent'
						return
					}
					// Redirect to the originally requested page with query object, or to the first available navbar item the user has access to
					if (this.previousRoute?.name) {
						this.$router.push({ name: this.previousRoute.name, query: this.previousRoute.query })
					} else {
						this.$router.push({ name: 'dashboard' })
					}
					this.saving = false
				})
				.catch(error => {
					if (error?.response?.status === 401) {
						if (error.response.data[0] === 'Account locked out') {
							this.$router.push({ name: 'account-locked-out' })
						}
					} else if (error?.response?.status === 403) {
						this.$router.push({ name: 'direct-login-not-allowed' })
					}
					this.tryingToLogIn = false
					this.authError = error
					this.saving = false
				})
		},
		navigateToForgottenPassword () {
			this.$router.push({ name: FORGOTTEN_PASSWORD })
		}
	}
}
</script>
